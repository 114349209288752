/* Section Title */

.title {
    color: #000000;
    padding: 0px;
    padding-left: 10px;
}

.navbar {
    padding: 0px!important;
}

.section-title-border {
    width: 50px;
    height: 2px;
    margin-bottom: 20px;
    background-color: red;
}

.margin-top-20 {
    margin-top: 20px;
}

.section-title-border.center {
    margin-right: auto;
    margin-left: auto;
    padding-top: 2px;
    height: 3px;
}

@media (max-width: 576px) {
    .title-heading {
        font-size: 28px;
    }
}

.buttonPanel {
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttonData {
    margin-left: 20px;
}