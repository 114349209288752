/* Section Title */

.title {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #ffffff;
    border-radius: 15px;
}

.titleNav {
    border-radius: 0px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px black solid;
}

.titleNavSub {
    color: black!important;
}

.titleIcon {
    color: white;
    margin-left: 20px;
}